import React from "react"
import createTemplate from "../../create-template"

import { useComponents } from "../../components"

function Cedar({ styles, ...props }) {
  const Elements = useComponents()

  const accent = styles.forElement("header").color

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page")}
    >
      <div className="formkit-container">
        <header>
          <div
            className="formkit-header-accent"
            style={{ backgroundColor: accent }}
          />
          <div className="formkit-header-background-container">
            <div
              className="formkit-header-background"
              style={styles.forElement("formkit_header_background")}
            />
          </div>
          <div className="formkit-header-container">
            <div className="formkit-header">
              <Elements.Heading
                className="formkit-heading formkit-heading-primary"
                name="header"
                defaults={{
                  content:
                    "Your catchy page headline goes here. Lorem ipsum dolor."
                }}
              />
              <Elements.Heading
                className="formkit-subheader"
                name="subheader"
                tag="h2"
                defaults={{
                  content:
                    "Short intro paragraph goes here. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse ornare."
                }}
              />
            </div>
            <div className="formkit-card" style={styles.forElement("card")}>
              <Elements.Heading
                className="formkit-heading formkit-heading-tertiary"
                name="form_header"
                tag="h2"
                defaults={{ content: "Register Today" }}
              />
              <Elements.Region
                className="formkit-form-content"
                name="form_content"
              >
                <Elements.Content
                  defaults={{
                    content:
                      "Do not miss your chance. Fill out the form below and sign up today."
                  }}
                />
              </Elements.Region>
              <Elements.Form
                style={styles.forElement("form", {
                  backgroundColor: "#ffffff"
                })}
              >
                <Elements.Errors />
                <Elements.CustomFields>
                  <Elements.AddFieldButton />
                  <Elements.Button
                    name="submit"
                    group="button"
                    defaults={{ content: "Get a free ebook" }}
                  />
                </Elements.CustomFields>
              </Elements.Form>
              <Elements.Content
                name="disclaimer"
                defaults={{
                  content: "We respect your privacy. Unsubscribe at any time."
                }}
              />
            </div>
          </div>
        </header>
        <div className="formkit-main-container">
          <div
            className="formkit-main-background"
            style={{
              backgroundColor: styles.forElement("content_background")
                .backgroundColor
            }}
          />
          <div
            className="formkit-main-background"
            style={styles.forElement("content_background")}
          />
          <div className="formkit-main">
            <div className="formkit-author">
              <Elements.Image
                className="formkit-avatar"
                name="image"
                size={{ h: 275, w: 225 }}
              />
              <div className="formkit-author-bio">
                <Elements.Content
                  name="name"
                  defaults={{ content: "First Last Name" }}
                />
                <Elements.Region name="title" className="formkit-title">
                  <Elements.Content
                    defaults={{ content: "New York Times Bestselling Author" }}
                  />
                </Elements.Region>
              </div>
            </div>
            <div className="formkit-columns">
              <div className="formkit-column">
                <Elements.Heading
                  className="formkit-heading formkit-heading-secondary"
                  name="about_label"
                  group="content_header"
                  tag="h3"
                  defaults={{ content: "Who am I?" }}
                />
                <Elements.Region
                  className="formkit-content"
                  name="about_content"
                  group="content"
                >
                  <Elements.Content
                    defaults={{
                      content: "Provide some more detail in this content area"
                    }}
                  />
                </Elements.Region>
              </div>
              <div className="formkit-column">
                <Elements.Heading
                  className="formkit-heading formkit-heading-secondary"
                  name="content_header"
                  tag="h3"
                  defaults={{ content: "What will you learn?" }}
                />
                <Elements.Region
                  className="formkit-content"
                  name="content"
                  group="content"
                >
                  <Elements.Content
                    defaults={{
                      content: "Provide some more detail in this content area"
                    }}
                  />
                </Elements.Region>
              </div>
            </div>
          </div>
        </div>
        <Elements.Content
          name="copyright"
          defaults={{
            content: `All rights reserved`
          }}
        />
        <Elements.BuiltWith background="page" />
      </div>
    </Elements.LandingPage>
  )
}

Cedar.style = "landing_page"
Cedar.categories = ["Product"]
Cedar.thumbnail = ""
Cedar.preview = "https://demo.ck.page/cedar"
Cedar.fields = [
  {
    name: "first_name",
    label: "Your first name"
  },
  {
    name: "email_address",
    label: "Your email address"
  }
]

export default createTemplate(Cedar, { name: "Cedar" })
